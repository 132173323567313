import React, { useEffect, useState } from "react"
import Layout from "../../components/layout"
import BreadCrumbs from "../../components/bread-crumbs"
import { isMobile } from "react-device-detect"

const breadCrumbsList = [
  {
    title: "Home",
    link: "/",
  },
]

const MagicLink = (props) => {
  const [showContent, setShowContent] = useState(false)
  useEffect(() => {
    const authToken = props.authToken
    if (isMobile) {
      window.location = "confidant://magiclink/" + authToken
    } else {
      setShowContent(true)
    }
  }, [])
  if (!showContent) {
    return null
  }
  return (
    <Layout>
      <div className="not-found-container">
        <div className="inner-container-magic">
          <h2 className="ml-heading">Whoops, You’ll have to try again.</h2>
          <p className="nf-parah">
            Confidant Magic Links will only work on your mobile device with the
            Confidant Health app. Please open the magic link with this mobile
            device.{" "}
          </p>
          <p className="nf-parah">
            Note:{" "}
            <b>
              Magic links expire after 30 minutes, so you may need to request a
              new one.{" "}
            </b>
          </p>
          <p className="nf-parah">See you soon,</p>
          <p className="nf-parah">Confidant Health</p>
          <p className="nf-parah">
            Have any questions? Please email{" "}
            <a className="mg-email" href="mailto:admin@confidanthealth.com">
              admin@confidanthealth.com
            </a>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default MagicLink
