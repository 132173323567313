import React, { Component } from "react"
import ArrowRightLong from "@mui/icons-material/ArrowRightAlt"

class BreadCrumbs extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="bread-crumbs">
        <div className="crumbs-inner">
          <div className="crumbs-side">
            {this.props.breadCrumbsList &&
              this.props.breadCrumbsList.length > 0 &&
              this.props.breadCrumbsList.map((breadCrumb, index) => {
                return (
                  <div key={index}>
                    <a href={breadCrumb.link} className="crumbs-text">
                      {breadCrumb.title}
                    </a>
                    {(this.props.currentPage ||
                      index !== this.props.breadCrumbsList.length - 1) && (
                      <ArrowRightLong />
                    )}
                  </div>
                )
              })}
            {this.props.currentPage && (
              <p className="crumbs-text-black">{this.props.currentPage}</p>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default BreadCrumbs
